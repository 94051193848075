var elem = document.querySelector('.person__inhalte');
var msnry = new Masonry(elem, {
  itemSelector: '.person__inhalt',
  columnWidth: '#person__spaltenbreite',
  gutter: '#person__spaltenabstand'
});

const videos = document.querySelectorAll('.person__bild-video');

let player = [];
videos.forEach(function (video, i) {
  // Vimeo-Player für jedes Video
  player[i] = new Vimeo.Player(video, {
    id: video.dataset.vimeoId
  });

  if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.intersectionRatio > 0) {
          player[i].play();
        } else {
          player[i].pause();
        }
      });
    });

    // 'video' wird überwacht
    observer.observe(video);
  } else {
    // Fallback für Geräte ohne Intersection Observer-Unterstützung
    player[i].play();
  }
});
